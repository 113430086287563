import '../App.css';
import { useQuery, gql } from '@apollo/client';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

const GET_DATA = gql`
{
  micronarrativas (first: 10000) {
    edges {
      node {
        title
        micronarrativasMeta {
          hotspot
          data
          latitude
          longitude
          tags_micro {
            edges {
              node {
                id
                name
              }
            }
          }
          narrativa
          imagem {
            node {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
}
`;

function useHotspotData(hotspotId) {
  const { loading, error, data } = useQuery(GET_DATA);

  if (loading) return { loading, error: null, data: [] };
  if (error) return { loading: false, error, data: [] };

  const filteredData = data.micronarrativas.edges
    .filter(({ node }) => node.micronarrativasMeta.hotspot === hotspotId)
    .map(({ node }) => ({
      id: node.id,
      narrativa: node.micronarrativasMeta.narrativa,
      data: node.micronarrativasMeta.data,
      tags: node.micronarrativasMeta.tags_micro.edges,
      imagem: node.micronarrativasMeta.imagem.node.mediaItemUrl
    }));

  return { loading: false, error: null, data: filteredData };
}

export default function Hotspots() {
  const [index, setIndex] = useState(0);
  const { hotspotId } = useParams();
  const { loading, error, data: micronarrativas } = useHotspotData(hotspotId);

  const handleClick = (newIndex) => {
    setIndex(newIndex);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  if (!micronarrativas.length) return <p>No data found for the selected hotspot.</p>;

  return (
    <div className='Wrapper'>
      <div className='Thumbnails'>
        {micronarrativas.map((item, id) => (
          <div onClick={() => handleClick(id)} className='Thumb' key={item.id}>
            <img src={item.imagem} alt={`Thumbnail ${id}`} />
          </div>
        ))}
      </div>
          
      <div className='Imagem'> 
        <img src={micronarrativas[index].imagem} alt="Selected" />
      </div>
          
      <div className='Narrativa'>
        <div className='Data'>
          <span>{new Date(micronarrativas[index].data).toLocaleDateString('pt-BR')}</span>
        </div>
        <div className='Corpo'>
        <div>
          <span>{micronarrativas[index].narrativa}</span>
        </div>
        <div className='Tags'>
          {
            micronarrativas[index].tags.map(({node, id}) => (
              <span key={id} className='Tag'>{node.name}</span>
            ))
          }
        </div>
        </div>
      </div> 
    </div>
  );
}